<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0">
    <v-layout
      v-if="isAdmin()"
      wrap>
      <v-flex md12>
        <material-card
          :title="$t('common.search')"
          color="green"
          flat
          full-width
          class="bg-white"
        >
          <v-form>
            <v-container py-0>
              <v-layout wrap>
                <v-flex
                  xs12
                  md3>
                  <v-text-field
                    v-model="accessCodeSearch.activeCode"
                    :label="$t('supplierAccessCode.activeCode')"
                    clearable
                    prepend-icon="mdi-qrcode"
                    @keyup.enter="onSearch"
                  />
                </v-flex>
                <v-flex
                  xs12
                  md3>
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="accessCodeSearch.expiredDate"
                        :label="$t('supplierAccessCode.expiredDate')"
                        persistent-hint
                        prepend-icon="mdi-calendar"
                        clearable
                        class="required"
                        @blur="date = parseDate(accessCodeSearch.expiredDate)"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="expiredDatepicker"
                      no-title
                      @input="menu = false"
                    />
                  </v-menu>
                </v-flex>
                <v-flex
                  xs12
                  md3>
                  <v-select
                    v-model="accessCodeSearch.status"
                    :items="supplierAccessCodeStatusList"
                    :label="$t('supplierAccessCode.status')"
                    prepend-icon="mdi-details"
                    class="required"
                    clearable
                  >
                    <template
                      slot="selection"
                      slot-scope="data">
                      <!-- HTML that describe how select should render selected items -->
                      {{ $t(data.item.text) }}
                      <!-- {{data}} -->
                    </template>
                    <template
                      slot="item"
                      slot-scope="data">
                      <!-- HTML that describe how select should render items when the select is open -->
                      {{ $t(data.item.text) }}
                      <!-- {{data}} -->
                    </template>
                  </v-select>
                </v-flex>
              </v-layout>
              <v-btn
                style="margin-right: 0"
                class="pull-right"
                color="success"
                @click="onSearch"
              >
                <span class="btn-label mr-3">
                  <i class="glyphicon glyphicon-search" />
                </span>
                <span style="text-transform: none">{{
                  $t("common.search")
                }}</span>
              </v-btn>
            </v-container>
          </v-form>
        </material-card>
        <material-card
          :title="$t('supplierAccessCode.title')"
          color="green"
          flat
          full-width
        >
          <template>
            <v-btn
              v-if="isAdmin()"
              color="success"
              dark
              style="float: right"
              @click="onShowModalAccessCode()"
            >
              {{ $t("common.add") }}
            </v-btn>
          </template>
          <v-data-table
            v-if="isAdmin()"
            :no-data-text="$t('common.noDataAvailable')"
            :headers="userHeaders"
            :items="supplierAccessCodeList"
            :single-select="true"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="text--darken-3 font-medium"
                v-text="$t(header.text)"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }">
              <td class="text-xs-center">{{ item.stt }}</td>
              <td class="text-xs-center">{{ item.activeCode }}</td>
              <td class="text-xs-center">{{ item.maxCount }}</td>
              <td class="text-xs-center">{{ item.count }}</td>
              <td class="text-xs-center">
                {{ getDate(item.expiredDate) }}
              </td>
              <td class="text-xs-center">
                {{ $t(getStatusAccessCode(item.status)) }}
              </td>
              <td class="text-xs-center">
                <v-tooltip
                  v-if="isAdmin()"
                  top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mx-2"
                      fab
                      small
                      color="success"
                      style="color: #ffffff"
                      v-on="on"
                      @click="onShowModalViewDetail(item)"
                    >
                      <v-icon dark>mdi-account-details</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("evoucher.viewDetail") }}</span>
                </v-tooltip>
              </td>
            </template>
          </v-data-table>
        </material-card>
      </v-flex>
    </v-layout>
    <pagination
      :total="accessCodePaginate.totalPage"
      :current-page="accessCodePaginate.currentPage"
      :row-per-page="accessCodePaginate.rowPerPage"
      @onPageChange="onPageChange"
    />
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
    <loadingBar :is-loading="isLoading" />
    <supplier-access-code-modal
      ref="supplierAccessCodeModal"
    />
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import functionUtils from 'utils/functionUtils'
import dateUtils from 'utils/dateUtils'
import ConfirmModal from 'Components/ConfirmModal'
import stringUtils from 'utils/stringUtils'
import SupplierAccessCodeModal from './SupplierAccessCodeModal'
import moment from 'moment'
export default {
  components: {
    ConfirmModal,
    'supplier-access-code-modal': SupplierAccessCodeModal
  },
  data: () => ({
    userHeaders: [
      {
        sortable: false,
        text: 'common.nonumber',
        value: 'stt',
        align: 'center'
      },
      {
        sortable: false,
        text: 'supplierAccessCode.activeCode',
        value: 'activeCode',
        align: 'center'
      },
      {
        sortable: false,
        text: 'supplierAccessCode.maxCount',
        value: 'maxCount',
        align: 'center'
      },
      {
        sortable: false,
        text: 'supplierAccessCode.count',
        value: 'count',
        align: 'center'
      },
      {
        sortable: false,
        text: 'supplierAccessCode.expiredDate',
        value: 'expiredDate',
        align: 'center'
      },
      {
        sortable: false,
        text: 'supplierAccessCode.status',
        value: 'status',
        align: 'center'
      },
      {
        sortable: false,
        text: 'common.actions',
        value: 'actions',
        align: 'center'
      }
    ],
    supplierAccessCodeList: [],
    accessCodePaginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    supplierAccessCodeStatusList: stringUtils.SupplierAccessCodeStatus,
    userSelected: {
      id: null
    },
    rolesSelected: [],
    isUpdate: true,
    dobDefault: null,
    menu: false,
    isShowUserDetail: true,
    confirmModalTitle: '',
    selected: [],
    accessCodeSearch: {
      activeCode: null,
      expiredDate: null,
      status: null
    },
    gender: stringUtils.GenderList,
    genderList: null,
    isLoading: false,
    isActive: false,
    expiredDatepicker: null
  }),
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR',
      'GET_SUPPLIER_ACCESS_CODE_LIST_DATA',
      'GET_CURRENT_ACCOUNT'
    ]),
    computedDateFormatted () {
      return this.formatDate(this.expiredDatepicker)
    }
  },
  watch: {
    GET_SUPPLIER_ACCESS_CODE_LIST_DATA () {
      let res = this.GET_SUPPLIER_ACCESS_CODE_LIST_DATA
      this.accessCodePaginate.currentPage = res.paginate.currentPage
      this.accessCodePaginate.totalPage = res.paginate.totalPage
      this.accessCodePaginate.rowPerPage = res.paginate.perPage
      let supplierAccessCodeList = res.results
      this.supplierAccessCodeList = []
      for (let i = 0, size = supplierAccessCodeList.length; i < size; i++) {
        let userObj = {
          stt: i + 1 + (res.paginate.currentPage - 1) * res.paginate.pageSize,
          activeCode: supplierAccessCodeList[i].active_code,
          maxCount: supplierAccessCodeList[i].max_count,
          count: supplierAccessCodeList[i].count,
          expiredDate: supplierAccessCodeList[i].expired_date,
          status: supplierAccessCodeList[i].status,
          id: supplierAccessCodeList[i].id
        }
        this.supplierAccessCodeList.push(userObj)
      }
      this.isLoading = false
    },
    expiredDatepicker (val) {
      this.accessCodeSearch.expiredDate = this.formatDate(
        this.expiredDatepicker
      )
    }
  },
  created () {
    this.getUserList()
  },
  methods: {
    // Check is admin
    isAdmin: function () {
      return functionUtils.isAdmin(this.GET_CURRENT_ACCOUNT)
    },
    /**
     * Format date
     */
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    /**
     * Show modal view detail user
     */
    onShowModalViewDetail: function (item) {
      this.$refs.supplierAccessCodeModal.onShowModal(
        this.accessCodePaginate.currentPage,
        item
      )
    },
    /**
     * Search
     */
    onSearch: function () {
      this.getUserList()
    },
    /**
     * Has action
     */
    hasAction: function (metaCode) {
      return functionUtils.hasAction(this.GET_CURRENT_ACCOUNT, metaCode)
    },
    /**
     * Get date of birth
     */
    getDate: function (dob) {
      return dateUtils.formatBeautyDate(dob, null)
    },
    /**
     * Parse date
     */
    parseDate (date) {
      if (!date) return null
      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    /**
     * Get user list
     */
    getUserList: function () {
      this.isLoading = true
      let filter = {
        params: {
          page: this.accessCodePaginate.currentPage,
          active_code:
            this.accessCodeSearch.activeCode != null
              ? this.accessCodeSearch.activeCode.trim()
              : null,
          expired_date: !functionUtils.isEmptyString(this.accessCodeSearch.expiredDate)
            ? moment(this.accessCodeSearch.expiredDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
            : null,
          status: this.accessCodeSearch.status
        }
      }
      this.GET_SUPPLIER_ACCESS_CODE_LIST(filter)
        .then(function () {
          this.isLoading = false
        })
        .catch(
          function () {
            this.isLoading = false
          }.bind(this)
        )
    },
    /**
     * Page change
     */
    onPageChange: function (page) {
      this.accessCodePaginate.currentPage = page
      this.getUserList()
    },
    onShowModalAccessCode: function () {
      this.$refs.supplierAccessCodeModal.onShowModal()
    },
    getStatusAccessCode: function (value) {
      let index = this.supplierAccessCodeStatusList.findIndex(
        (x) => x.value === value
      )
      return index !== -1
        ? this.supplierAccessCodeStatusList[index].text
        : null
    },
    ...mapActions([
      'GET_SUPPLIER_ACCESS_CODE_LIST',
      'UPDATE_USER_PROFILE',
      'ON_SHOW_TOAST',
      'ACTIVE_USER'
    ])
  }
}
</script>
<style lang="scss" scoped>
@import "../../styles/material-dashboard/_colors.scss";
/deep/ .test-primary {
  background-color: #c7c7c7c7 !important;
}
/deep/ .test-primary:hover {
  background-color: #c7c7c7c7 !important;
}
/deep/ .theme--light.v-list {
  background-color: transparent;
}
.border-style {
  border-bottom: 2px solid $border-color;
}
.cursor-pointer {
  cursor: pointer !important;
}
</style>
