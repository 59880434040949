<template>
  <v-dialog
    v-model="isShowModal"
    persistent
    max-width="800px"
  >
    <v-card>
      <material-card
        :title="$t('supplierAccessCode.title')"
        tile
        color="green"
        full-width
      >
        <v-container grid-list-xl>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-layout wrap>
              <v-flex
                xs12
                sm8
                md6>
                <v-text-field
                  :rules="[ruleRequiredValue, ruleValidCode]"
                  v-model="supplierAccessCodeData.activeCode"
                  :label="$t('supplierAccessCode.activeCode')"
                  :name="$t('supplierAccessCode.activeCode')"
                  prepend-icon="mdi-qrcode"
                  type="text"
                  class="required"
                  clearable
                />
              </v-flex>
              <v-flex
                xs12
                sm8
                md6>
                <v-text-field
                  :rules="[ruleRequiredValue]"
                  v-model="supplierAccessCodeData.maxCount"
                  :label="$t('supplierAccessCode.maxCount')"
                  :name="$t('supplierAccessCode.maxCount')"
                  prepend-icon="mdi-numeric"
                  type="number"
                  class="required"
                  clearable
                />
              </v-flex>
              <v-flex
                xs12
                sm8
                md6>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="supplierAccessCodeData.expiredDate"
                      :label="$t('supplierAccessCode.expiredDate')"
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      clearable
                      @blur="date = parseDate(supplierAccessCodeData.expiredDate)"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="expiredDatepicker"
                    no-title
                    @input="menu = false"
                  />
                </v-menu>
              </v-flex>
              <v-flex
                xs12
                sm8
                md6>
                <v-select
                  v-model="supplierAccessCodeData.status"
                  :items="supplierAccessCodeStatus"
                  :label="$t('supplierAccessCode.status')"
                  prepend-icon="mdi-details"
                  class="required"
                >
                  <template
                    slot="selection"
                    slot-scope="data">
                    <!-- HTML that describe how select should render selected items -->
                    {{ $t(data.item.text) }}
                    <!-- {{data}} -->
                  </template>
                  <template
                    slot="item"
                    slot-scope="data">
                    <!-- HTML that describe how select should render items when the select is open -->
                    {{ $t(data.item.text) }}
                    <!-- {{data}} -->
                  </template>
                </v-select>
              </v-flex>
              <v-flex
                xs12
                sm8
                md6>
                <v-text-field
                  v-if="supplierAccessCodeData.id != 0"
                  v-model="supplierAccessCodeData.count"
                  :label="$t('supplierAccessCode.count')"
                  :name="$t('supplierAccessCode.count')"
                  prepend-icon="mdi-numeric"
                  type="text"
                  readonly
                  disabled
                />
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal">
          {{ $t("common.close") }}
        </v-btn>
        <v-btn
          small
          color="success darken-1"
          @click="onConfirm">
          {{ $t("common.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
    <confirm-modal
      ref="confirmModal"
      :title="confirmModalTitle"
      @onConfirm="confirmCreateActiveCode"
    />
  </v-dialog>
</template>
<script>
import functionUtils from 'utils/functionUtils'
import validationRules from 'utils/validationRules'
import stringUtils from 'utils/stringUtils'
import { mapActions, mapGetters } from 'vuex'
import ToastType from 'enum/toastType'
import ConfirmModal from 'Components/ConfirmModal'
import moment from 'moment'
export default {
  components: {
    ConfirmModal
  },
  data () {
    return {
      valid: false,
      isShowModal: false,
      expiredDatepicker: null,
      menu: false,
      currentPage: 1,
      //
      supplierAccessCodeData: {
        id: 0,
        activeCode: null,
        maxCount: 1,
        count: 0,
        expiredDate: null,
        status: 0,
        confirmCreate: false
      },
      supplierAccessCodeStatus: stringUtils.SupplierAccessCodeStatus,
      confirmModalTitle: '',
      activeCodeDataSelected: null
    }
  },
  computed: {
    computedDateFormatted () {
      return this.formatDate(this.expiredDatepicker)
    },
    ...mapGetters([
      'GET_SNACK_BAR'
    ])
  },
  watch: {
    expiredDatepicker (val) {
      this.supplierAccessCodeData.expiredDate = this.formatDate(this.expiredDatepicker)
    }
  },
  methods: {
    /**
     * Required value
     */
    ruleRequiredValue (value) {
      return validationRules.ruleRequiredValue(value)
    },
    ruleValidCode (value) {
      return validationRules.ruleValidCode(value)
    },
    /**
     * Format date
     */
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    /**
     * Parse date
     */
    parseDate (date) {
      if (!date) return null
      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    /**
     * Check null
     */
    isNull: function (data) {
      return functionUtils.isNull(data)
    },
    /**
     * Show modal
     */
    onShowModal: function (currentPage, data) {
      this.isShowModal = true
      if (currentPage !== undefined && data !== undefined) {
        this.currentPage = currentPage
        this.supplierAccessCodeData = {
          id: data.id,
          activeCode: data.activeCode,
          maxCount: data.maxCount,
          count: data.count,
          expiredDate: !functionUtils.isEmptyString(data.expiredDate)
            ? moment(data.expiredDate).format('DD/MM/YYYY')
            : null,
          status: data.status,
          confirmCreate: false
        }
      } else {
        this.supplierAccessCodeData = {
          id: 0,
          activeCode: null,
          maxCount: 1,
          count: 0,
          expiredDate: null,
          status: 0,
          confirmCreate: false
        }
      }
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.isShowModal = false
    },
    /**
     * Confirm
     */
    onConfirm: function () {
      let currentDate = moment().format('YYYY/MM/DD')
      let expiredDate = moment(
        this.supplierAccessCodeData.expiredDate,
        'DD/MM/YYYY'
      ).format('YYYY/MM/DD')
      if (this.$refs.form.validate()) {
        if (
          !functionUtils.isEmptyString(
            this.supplierAccessCodeData.expiredDate
          ) &&
          expiredDate < currentDate &&
          this.supplierAccessCodeData.status === this.supplierAccessCodeStatus[0].value
        ) {
          this.ON_SHOW_TOAST({
            message: this.$t('supplierAccessCode.errorExpiredDate'),
            styletype: ToastType.ERROR
          })
        } else {
          this.snackbar = true
          let endDateTime = '23:59:59'
          let filter = {
            id: this.supplierAccessCodeData.id,
            active_code: this.supplierAccessCodeData.activeCode,
            max_count: this.supplierAccessCodeData.maxCount,
            expired_date: !functionUtils.isEmptyString(
              this.supplierAccessCodeData.expiredDate
            ) ? moment(
                this.supplierAccessCodeData.expiredDate + ' ' + endDateTime,
                'DD/MM/YYYY HH:mm:ss'
              ).format('YYYY-MM-DD HH:mm:ss') : null,
            status: this.supplierAccessCodeData.status,
            confirmCreate: this.supplierAccessCodeData.confirmCreate
          }
          if (this.supplierAccessCodeData.id === 0) {
            this.CREATE_SUPPLIER_ACCESS_CODE(filter)
              .then(
                function () {
                  this.ON_SHOW_TOAST({
                    message: this.$t('common.success'),
                    styleType: ToastType.SUCCESS
                  })
                  this.isShowModal = false
                  let filter = {
                    params: {
                      page: this.currentPage
                    }
                  }
                  this.GET_SUPPLIER_ACCESS_CODE_LIST(filter)
                }.bind(this)
              )
              .catch(
                function (error) {
                  let errorCode = error.response.data.code
                  if (errorCode !== undefined && errorCode === 'active_code_exists') {
                    this.confirmModalTitle = this.$t('supplierAccessCode.confirmCreateActiveCodeExistTitle')
                    this.activeCodeDataSelected = filter
                    this.$refs.confirmModal.onShowModal()
                  } else if (!functionUtils.isNull(error.response.data.errorText)) {
                    let errorText = error.response.data.errorText
                    this.ON_SHOW_TOAST({
                      message: this.$t(`${errorText}`),
                      styletype: ToastType.ERROR
                    })
                  } else {
                    this.ON_SHOW_TOAST({
                      message: this.$t('login.somethingWentWrong'),
                      styletype: ToastType.ERROR
                    })
                  }
                }.bind(this)
              )
          } else {
            this.UPDATE_SUPPLIER_ACCESS_CODE(filter)
              .then(
                function () {
                  this.ON_SHOW_TOAST({
                    message: this.$t('common.success'),
                    styleType: ToastType.SUCCESS
                  })
                  this.isShowModal = false
                  let filter = {
                    params: {
                      page: this.currentPage
                    }
                  }
                  this.GET_SUPPLIER_ACCESS_CODE_LIST(filter)
                }.bind(this)
              )
              .catch(
                function (error) {
                  if (!functionUtils.isNull(error.response.data.errorText)) {
                    let errorText = error.response.data.errorText
                    this.ON_SHOW_TOAST({
                      message: this.$t(`${errorText}`),
                      styletype: ToastType.ERROR
                    })
                  } else {
                    this.ON_SHOW_TOAST({
                      message: this.$t('login.somethingWentWrong'),
                      styletype: ToastType.ERROR
                    })
                  }
                }.bind(this)
              )
          }
        }
      }
      // }
    },
    confirmCreateActiveCode: function () {
      this.activeCodeDataSelected.confirmCreate = true
      this.CREATE_SUPPLIER_ACCESS_CODE(this.activeCodeDataSelected)
        .then(
          function () {
            this.ON_SHOW_TOAST({
              message: this.$t('common.success'),
              styleType: ToastType.SUCCESS
            })
            this.isShowModal = false
            let filter = {
              params: {
                page: this.currentPage
              }
            }
            this.GET_SUPPLIER_ACCESS_CODE_LIST(filter)
          }.bind(this)
        )
        .catch(
          function (error) {
            if (!functionUtils.isNull(error.response.data.errorText)) {
              let errorText = error.response.data.errorText
              this.ON_SHOW_TOAST({
                message: this.$t(`${errorText}`),
                styletype: ToastType.ERROR
              })
            } else {
              this.ON_SHOW_TOAST({
                message: this.$t('login.somethingWentWrong'),
                styletype: ToastType.ERROR
              })
            }
          }.bind(this)
        )
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'CREATE_SUPPLIER_ACCESS_CODE',
      'GET_SUPPLIER_ACCESS_CODE_LIST',
      'UPDATE_SUPPLIER_ACCESS_CODE'
    ])
  }
}
</script>

<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>
